import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'hnsft71d',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skIG0xNSv4I0mQIaT5Wa8CjFqpCfODK6tgUU5oFLttSUWn8E9TOCTpVM1R6qzsfdk7zmCjQQmP4joLRfoCbUDRKeH4KwcCSATVculPpEviuXrnMcGBm55wKwu1h1MS4mrEacV7eMrAZGLBSyMzpMXlM2eqhbasbh7ML2NBsgBmZ21rK0tFhZ',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

