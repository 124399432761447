import React, { useState, useEffect } from 'react';
// import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import { Header, About, Work, Skills, Footer } from './container';
import { client } from './client';
import './App.scss';

const App = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const query = '*[_type == "user" && active==true]';

    client.fetch(query).then((data) => {
      setUser(data);
      console.log('app.js');
      console.log(data[0].phone);
      console.log(user);
    });
  }, []);
  if (user.length < 1) {
    return null;
  }
  return (
    <div className="app">
      <Navbar logo={user[0].logo} />
      <Header />
      <About />
      <Work />
      <Skills />
      {/* <Testimonial /> */}
      <Footer user={user[0]} />
    </div>
  );
};

export default App;
