import React, { useEffect, useState } from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF, FaWhatsapp, FaGithub, FaHackerrank, FaLinkedin } from 'react-icons/fa';
import { client } from '../client';

const SocialMedia = () => {
  const [githubLink, setGithubLink] = useState([]);
  const [hackerrankLink, setHackerrankLink] = useState([]);
  const [linkedinLink, setLinkedinLink] = useState([]);
  const [twitterLink, setTwitterLink] = useState([]);
  const [instagramLink, setInstagramLink] = useState([]);
  const [facebookLink, setFacebookLink] = useState([]);
  const [whatsappLink, setWhatsappLink] = useState([]);
  useEffect(() => {
    const query = '*[_type == "social" && active==true]';

    client.fetch(query).then((data) => {
      console.log('***********social********');
      console.log(data);
      data.forEach((element) => {
        switch (element.name) {
          case 'github':
            console.log('Github inside switch');
            setGithubLink(element.link);
            break;
          case 'hackerrank':
            setHackerrankLink(element.link);
            break;
          case 'linkedin':
            setLinkedinLink(element.link);
            break;
          case 'twitter':
            setTwitterLink(element.link);
            break;
          case 'instagram':
            setInstagramLink(element.link);
            break;
          case 'facebook':
            setFacebookLink(element.link);
            break;
          case 'whatsapp':
            setWhatsappLink(element.link);
            break;
          default:
            console.log('inside default');
        }
      });
    });
  }, []);

  return (
    <div className="app__social">
      <div>
        <a href={githubLink} target="_blank" rel="noopener noreferrer"> <FaGithub /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div>
        <a href={hackerrankLink} target="_blank" rel="noopener noreferrer"> <FaHackerrank /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div>
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer"> <FaLinkedin /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div style={{ display: 'none' }}>
        <a href={twitterLink} target="_blank" rel="noopener noreferrer"> <BsTwitter /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div>
        <a href={instagramLink} target="_blank" rel="noopener noreferrer"> <BsInstagram /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div style={{ display: 'none' }}>
        <a href={facebookLink} target="_blank" rel="noopener noreferrer"> <FaFacebookF /><span style={{ display: 'none' }}>hide</span></a>
      </div>
      <div style={{ display: 'none' }}>
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer"> <FaWhatsapp /><span style={{ display: 'none' }}>hide</span></a>
      </div>
    </div>
  );
};

export default SocialMedia;
