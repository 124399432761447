import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import './Header.scss';
import { client, urlFor } from '../../client';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    const query = '*[_type == "header" && active==true]';

    client.fetch(query).then((data) => {
      setHeaderData(data);
    });
  }, []);
  console.log('*******************');
  console.log(headerData);
  return (
    <div className="app__header app__flex">
      {
        (() => {
          if (headerData.length > 0) {
            return (
              <>
                <motion.div
                  whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__header-info"
                >
                  <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                      <span>👋</span>
                      <div style={{ marginLeft: 20 }}>
                        <p className="p-text">Hello, I am</p>
                        <h1 className="head-text">{headerData[0].name}</h1>
                      </div>
                    </div>

                    <div className="tag-cmp app__flex">
                      <p className="p-text">{headerData[0].description}</p>
                      {/* <p className="p-text">Freelancer</p> */}
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5, delayChildren: 0.5 }}
                  className="app__header-img"
                >
                  <img src={urlFor(headerData[0].image)} alt="profile_bg" />
                  <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={urlFor(headerData[0].imageCircle)}
                    alt="profile_circle"
                    className="overlay_circle"
                  />
                </motion.div>

                <motion.div
                  variants={scaleVariants}
                  whileInView={scaleVariants.whileInView}
                  className="app__header-circles"
                >
                  {headerData[0].skillIconList.map((circle, index) => (
                    <div className="circle-cmp app__flex" key={`circle-${index}`}>
                      <img src={urlFor(circle)} alt="profile_bg" />
                    </div>
                  ))}
                </motion.div>
              </>
            );
          }
          return null;
        })()
      }

    </div>
  );
};

export default AppWrap(Header, 'home');
